import React from 'react'
import Img from 'gatsby-image'
import queryAllPressImages from 'src/queries/queryAllPressImages'
import App from 'src/components/App/App'
import Head from 'src/components/Head/Head'
import Header from 'src/components/Header/Header'
import styles from './press.module.scss'

const Press = () => {
  const nodes = queryAllPressImages()

  const renderImage = ({ index }) => {
    const node = nodes[index]
    const isJpgOrPng = node.extension === 'jpg' || node.extension === 'png'

    return isJpgOrPng ? (
      <Img
        fluid={node.childImageSharp.fluid}
        objectFit="cover"
        objectPosition="50% 50%"
        className={styles.contentImg}
      />
    ) : (
      <img src={node.publicURL} className={styles.svgImage} />
    )
  }

  return (
    <App>
      <Head />
      <Header pageTitle="Press" />
      <main className={styles.main}>
        <article className={styles.content}>
          <div className={styles.imageWrapper}>{renderImage({ index: 0 })}</div>
          <section className={styles.contentInfo}>
            <header className={styles.contentHeader}>
              <a
                href="https://www.digitaltrends.com/photography/mobile-creatives-videoblocks-study/"
                className={styles.contentHeaderLink}
                target="_blank"
              >
                NEARLY A THIRD USE SMARTPHONES AND TABLETS FOR CREATIVE
                PROJECTS, STUDY SHOWS
              </a>
              <em className={styles.contentSubtitle}>DIGITAL TREND</em>
            </header>
            <p className={styles.contentDesc}>
              "The 1,500 users surveyed named Adobe Spark, Canva and Pic Collage
              as the top creative mobile apps."
            </p>
          </section>
        </article>
        <article className={styles.content}>
          <div className={styles.imageWrapper}>{renderImage({ index: 1 })}</div>
          <section className={styles.contentInfo}>
            <header className={styles.contentHeader}>
              <a
                href="https://download.cnet.com/news/top-3-photo-collage-apps-for-android/"
                className={styles.contentHeaderLink}
                target="_blank"
              >
                Top 3 photo collage apps for Android
              </a>
              <em className={styles.contentSubtitle}>CNET</em>
            </header>
            <p className={styles.contentDesc}>
              "Pic Collage is more of a virtual pinboard than the
              grid-template-heavy setups like Photo Grid, but it is buttery
              smooth. The app's general interface evokes a playful and fun
              emotion, characterized by gesture controls, hand-drawn icons, and
              lots of bright, spring-season color schemes."
            </p>
          </section>
        </article>
        <article className={styles.content}>
          <div className={styles.imageWrapper}>{renderImage({ index: 2 })}</div>
          <section className={styles.contentInfo}>
            <header className={styles.contentHeader}>
              <a
                href="http://www.usatoday.com/story/tech/2013/01/04/pic-collage-photos-app-insider/1809211/"
                className={styles.contentHeaderLink}
                target="_blank"
              >
                Pic Collage: Free app spices up your family photos
              </a>
              <em className={styles.contentSubtitle}>USA Today</em>
            </header>
            <p className={styles.contentDesc}>
              "Whether you're 6 or 106, you'll find the free Pic Collage app an
              excellent way to dress up your photo collection and share your
              handiwork."
            </p>
          </section>
        </article>
        <article className={styles.content}>
          <div className={styles.imageWrapper}>{renderImage({ index: 3 })}</div>
          <section className={styles.contentInfo}>
            <header className={styles.contentHeader}>
              <a
                href="http://techcrunch.com/2013/08/21/piccollage-the-sticker-happy-collaging-app-raises-2-3m-in-seed-funding-and-aims-to-build-out-its-social-platform/"
                className={styles.contentHeaderLink}
                target="_blank"
              >
                PicCollage, The Sticker-Happy Collaging App, Raises $2.3M In
                Seed Funding
              </a>
              <em className={styles.contentSubtitle}>Techcrunch</em>
            </header>
            <p className={styles.contentDesc}>
              "PicCollage is essentially a layer on top of image collection and
              sharing tools like Pinterest, Tumblr or Instagram. It lets users
              take the images they’ve so lovingly collected, remix them
              together, add stickers and text, and then upload to various social
              outlets again. To date, the app has seen 35 million downloads."
            </p>
          </section>
        </article>
        <article className={styles.content}>
          <div className={styles.imageWrapper}>{renderImage({ index: 4 })}</div>
          <section className={styles.contentInfo}>
            <header className={styles.contentHeader}>
              <a
                href="http://venturebeat.com/2014/10/22/mediaspike-weaves-movie-ads-as-product-placements-into-the-piccollage-photo-app/"
                className={styles.contentHeaderLink}
                target="_blank"
              >
                MediaSpike weaves movie ads as product placements into the
                PicCollage photo app
              </a>
              <em className={styles.contentSubtitle}>VentureBeat</em>
            </header>
            <p className={styles.contentDesc}>
              "To drive awareness of Oct. 24 release of Universal Pictures’
              spooky movie Ouija, MediaSpike has created ads that are a core
              part of the PicCollage user experience."
            </p>
          </section>
        </article>
        <article className={styles.content}>
          <div className={styles.imageWrapper}>{renderImage({ index: 5 })}</div>
          <section className={styles.contentInfo}>
            <header className={styles.contentHeader}>
              <a
                href="http://www.mercurynews.com/travel/ci_19819342"
                className={styles.contentHeaderLink}
                target="_blank"
              >
                Around the Web: PicCollage
              </a>
              <em className={styles.contentSubtitle}>San Jose Mercury News</em>
            </header>
            <p className={styles.contentDesc}>
              "It could be the fastest way you'll ever find to organize a batch
              of photos."
            </p>
          </section>
        </article>
        <article className={styles.content}>
          <div className={styles.imageWrapper}>{renderImage({ index: 6 })}</div>
          <section className={styles.contentInfo}>
            <header className={styles.contentHeader}>
              <a
                href="http://www.latimes.com/news/custom/topofthetimes/features/la-tr-webbuzz-20120108,0,4581874.story"
                className={styles.contentHeaderLink}
                target="_blank"
              >
                Web Buzz: PicCollage helps organize photos on iPhone, iPad
              </a>
              <em className={styles.contentSubtitle}>LA Times</em>
            </header>
            <p className={styles.contentDesc}>
              "You can impress your mother, brag to your co-workers, and make
              your vacation look even better than it really was, all before you
              pack your suitcase to return home."
            </p>
          </section>
        </article>
        <article className={styles.content}>
          <div className={styles.imageWrapper}>{renderImage({ index: 7 })}</div>
          <section className={styles.contentInfo}>
            <header className={styles.contentHeader}>
              <a
                href="http://www.nikkei.com/news/headline/article/g=96958A9C93819499E0E4E2E2808DE0E4E2E6E0E2E3E0E2E2E2E2E2E2;df=2"
                className={styles.contentHeaderLink}
                target="_blank"
              >
                iPadの大画面ならコラージュが作りやすい
              </a>
              <em className={styles.contentSubtitle}>
                Nikkei Shimbun 日本経済新聞
              </em>
            </header>
            <p className={styles.contentDesc}>
              "複数の写真を自在に配置してコラージュが作れるアプリ「PicCollage
              写真コラージュ」。無料とは思えない機能の充実ぶりで、愛用者の多い1本だ。"
            </p>
          </section>
        </article>
      </main>
    </App>
  )
}

export default Press
